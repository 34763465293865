import Modal, { ModalHeader, ModalBody, ModalFooter } from '@this/template/components/bootstrap/Modal';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FormGroup from '@this/template/components/bootstrap/forms/FormGroup';
import Input from '@this/template/components/bootstrap/forms/Input';
import api from '@this/impl/api';
import Button from '@this/template/components/bootstrap/Button';
import Dropdown, { DropdownMenu, DropdownToggle } from '@this/template/components/bootstrap/Dropdown';
import Checks, { ChecksGroup } from '@this/template/components/bootstrap/forms/Checks';
import InputGroup from '@this/template/components/bootstrap/forms/InputGroup';
import { useFilterContext } from '@this/impl/FilterContext';
import { EntityField } from '../../components/entity.field';
import EntityModalSearch from '../../components/entity.modal.search';

const SearchHeader = () => {
	const categoriesQuery = api.Contents.useCategories();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	
	// Use the FilterContext instead of local state
	const { 
		filters, 
		setKeywords, 
		setCategories, 
		setParent, 
		setLabels,
		applyFilters,
		clearFilter
	} = useFilterContext();

	const [searchModalStatus, setSearchModalStatus] = useState(false);
	const [parentName, setParentName] = useState<string>(filters.parentName || '');
	const [searchStructureVisible, setSearchStructureVisible] = useState(false);

	const handleFilterChange = (e: any) => {
		setKeywords(e.target.value);
	};

	const search = () => {
		applyFilters();
	};

	const handleKeyDown = (e: any) => {
		if (e.key === 'Enter') search();
	}

	const handleAdvancedSearch = () => {
		setSearchModalStatus(false);
		applyFilters();
	};

	const handleCategoryChange = (e: any) => {
		const value = e.target.value === 'none' ? undefined : e.target.value;
		setCategories(value ? [value] : undefined);
	};

	const handleLabelsChanges = (e: any) => {
		setLabels(e);
	}

	const openSearch = () => {
		setSearchModalStatus(true);
	}

	// Sync parent name with the parent ID
	useEffect(() => {
		if (filters.parentName) {
			setParentName(filters.parentName);
		}
	}, [filters.parentName]);

	const handleSearchClose = () => {
		setSearchStructureVisible(false);
	}

	const handleElementSelect = (item: any) => {
		const id = item._id ?? item.id;
		const name = item.slug ?? item.name;
		setParent(id, name);
		setParentName(name);
		setSearchStructureVisible(false);
	}

	const handleSearchStructure = () => setSearchStructureVisible(true);
	
	const handleClearStructure = () => { 
		setParent(undefined, '');
		setParentName('');
	}

	const structuresTreeQuery = api.Public.useStructures();

	const structureSelectionFields: EntityField[] = [
		{
			name: 'name',
			title: 'Nombre',
			sortable: true,
			selectable: true,
			filter: true,
			className: 'text-capitalize link-primary underline',
			click: (item: any) => handleElementSelect(item),
		},
		{
			name: 'links.download',
			sortable: false,
			isButton: true,
			className: 'text-center',
			visible: () => true,
			value: (item: any) => 'Seleccionar Estructura',
			shrink: true,
			click: (item: any) => handleElementSelect(item),
		},
	];

	return (
		<div>
			<div className='input-group'>
				<Input
					id='filters'
					name='filters'
					className='form-control '
					value={filters.keywords ?? ''}
					onChange={handleFilterChange}
					onKeyDown={handleKeyDown}
					placeholder='buscar en la intranet'
				/>
				<span className='input-group-text' id='Buscar en la intranet'>
					<a
						href='#'
						className='link-dark'
						data-bs-toggle='modal'
						data-bs-target='#searchModal'
						aria-label='buscar'
						onClick={openSearch}>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='15'
							height='15'
							fill='currentColor'
							className='bi bi-sliders2'
							viewBox='0 0 16 16'>
							<path
								fillRule='evenodd'
								d='M10.5 1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4H1.5a.5.5 0 0 1 0-1H10V1.5a.5.5 0 0 1 .5-.5ZM12 3.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5Zm-6.5 2A.5.5 0 0 1 6 6v1.5h8.5a.5.5 0 0 1 0 1H6V10a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5ZM1 8a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2A.5.5 0 0 1 1 8Zm9.5 2a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V13H1.5a.5.5 0 0 1 0-1H10v-1.5a.5.5 0 0 1 .5-.5Zm1.5 2.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5Z'
							/>
						</svg>
					</a>
				</span>
			</div>

			<EntityModalSearch
				fields={structureSelectionFields}
				title='Seleccionar Estructura'
				entityName='Estructuras'
				isTree
				entityQuery={structuresTreeQuery}
				visible={searchStructureVisible}
				onClose={handleSearchClose}
				onSelect={handleElementSelect}
				onSelectIcon='ArrowForward'
			/>

			<Modal
				setIsOpen={setSearchModalStatus}
				isOpen={searchModalStatus}
				isStaticBackdrop
				size="lg"
				data-tour='search-modal'>
				<ModalHeader setIsOpen={setSearchModalStatus} className='border-bottom'>
					<h2>Busqueda Avanzada</h2>
				</ModalHeader>
				<ModalBody>
					<div className='h-auto d-inline-block w-100'>
						<div className='d-flex flex-column p-3 h-100'>
							<div className='align-self-stretch h-auto d-inline-block'>

								<FormGroup
									id='name'
									label='Categoría'
									labelClassName='col-sm-12 text-capitalize mt-2'
									childWrapperClassName='col-sm-12 mt-2'
									className='mb-3 flex-grow-1'>
									<InputGroup>
										<Dropdown className='flex-grow-1'>
											<DropdownToggle>
												<Input
													id='filter'
													name='filter'
													className='flex-grow-1'
													value={categoriesQuery.all?.data?.find((cat) => cat.id === filters.categories?.[0])?.name ?? 'No seleccionado'}
													readOnly
												/>

											</DropdownToggle>
											<DropdownMenu
												className='w-100 text-start'
												style={{
													height: '100px;',
													backgroundColor: '',
													overflow: 'auto',
												}}>
												{categoriesQuery.tree?.data && (
													<ChecksGroup className='m-2'>
														{categoriesQuery.tree?.data
															.filter((c) => c.status === 'active' && c.visible !== false)
															.map((c) => {
																return (
																	<div key={c._id}>
																		<Checks
																			type='switch'
																			name='profiles'
																			label={c.name}
																			value={c._id}
																			checked={filters.categories?.[0] === c._id}
																			onChange={handleCategoryChange}
																		/>
																		{c.children?.map((c1) => {
																			return (
																				<Checks
																					key={c1._id}
																					type='switch'
																					name='profiles'
																					label={c1.name}
																					value={c1._id}
																					checked={filters.categories?.[0] === c1._id}
																					onChange={handleCategoryChange}
																					className='ms-3'
																				/>
																			);
																		})}
																	</div>
																);
															})}
													</ChecksGroup>
												)}
											</DropdownMenu>
										</Dropdown>
										{filters.categories?.length ? 
											<Button onClick={() => clearFilter('categories')} color='warning' className='ms-4' size='sm'>Deseleccionar</Button> 
											: <span> </span>}
									</InputGroup>
								</FormGroup>
								<FormGroup
									id='filterQuery'
									label='Palabras Clave'
									labelClassName='text-capitalize'
									childWrapperClassName='d-flex'
									className='mb-3'>
									<Input
										id='filter'
										name='filter'
										className='flex-grow-1'
										value={filters.keywords ?? ''}
										onChange={handleFilterChange}
									/>
								</FormGroup>
								<FormGroup
									id='structure'
									label='Estructura'
									labelClassName='text-capitalize'
									childWrapperClassName='d-flex'
									className='mb-3'>
									<Input
										className='flex-grow-1'
										value={parentName}
										readOnly
									/>
									<Button onClick={handleSearchStructure} color='primary' className='ms-4' size='sm'>Seleccionar</Button>
									{filters.parent ? 
										<Button onClick={handleClearStructure} color='warning' className='ms-4' size='sm'>Deseleccionar</Button> 
										: <span> </span>}
								</FormGroup>
							</div>
						</div>
					</div>
				</ModalBody>
				<ModalFooter className='d-flex flex-column h-90 border-top'>
					<div className='d-flex p-3 mt-2'>
						<Button color='primary' className='w-100 m-2' onClick={handleAdvancedSearch}>
							Buscar
						</Button>
					</div>
				</ModalFooter>
			</Modal>
		</div>
	);
};

export default SearchHeader;
