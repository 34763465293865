import React from 'react';
import Input from '@this/template/components/bootstrap/forms/Input';
import { useNavigate } from 'react-router-dom';
import { useFilterContext } from '@this/impl/FilterContext';

const Search = () => {
	const navigate = useNavigate();
	const { filters, setKeywords, setConfiguration, applyFilters } = useFilterContext();

	const handleFilterChange = (e: any) => {
		setKeywords(e.target.value);
	};

	const search = () => {
		// Set the configuration for form search
		setConfiguration('formSearch');
		// Apply all filters (including the configuration)
		applyFilters();
	};

	const handleKeyDown = (e: any) => {
		if (e.key === 'Enter') search();
	}

	return (
		<div className='aside-search'>
			<div className='input-group mb-4'>
				<Input 
					type='email' 
					autoComplete='email' 
					placeholder='Buscar Formularios' 
					aria-label='Buscar Formulario'
					className='form-control bg-02 border-0'
					value={filters.keywords}
					onChange={handleFilterChange}
					onKeyDown={handleKeyDown}
				/>

				<span className='input-group-text bg-02 border-0' id='Buscar en la intranet'>
					<a href='#' className='link-light' onClick={search} aria-label='buscar'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							fill='currentColor'
							className='bi bi-arrow-right-square-fill'
							viewBox='0 0 16 16'>
							<path d='M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1z' />
						</svg>
					</a>
				</span>
			</div>
		</div>
	);
};

export default Search;
