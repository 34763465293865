import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

interface FilterContextType {
	filters: {
		keywords: string;
		categories?: string[];
		labels?: string[];
		parent?: string;
		parentName?: string;
		configuration?: string;
	};
	setKeywords: (keywords: string) => void;
	setCategories: (categories?: string[]) => void;
	setLabels: (labels?: string[]) => void;
	setParent: (parent?: string, parentName?: string) => void;
	setConfiguration: (configuration?: string) => void;
	clearFilter: (filterType: 'keywords' | 'categories' | 'labels' | 'parent') => void;
	clearAllFilters: () => void;
	applyFilters: () => void;
}

const FilterContext = createContext<FilterContextType | undefined>(undefined);

export const FilterProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();

	const [filters, setFilters] = useState({
		keywords: searchParams.get('q') ?? '',
		categories: searchParams.get('categories')?.split(',').filter(Boolean) ?? undefined,
		labels: searchParams.get('labels')?.split(',').filter(Boolean) ?? undefined,
		parent: searchParams.get('parent') ?? undefined,
		parentName: '',
		configuration: searchParams.get('configuration') ?? undefined,
	});

	// Sync URL params with state when URL changes
	useEffect(() => {
		setFilters({
			keywords: searchParams.get('q') ?? '',
			categories: searchParams.get('categories')?.split(',').filter(Boolean) ?? undefined,
			labels: searchParams.get('labels')?.split(',').filter(Boolean) ?? undefined,
			parent: searchParams.get('parent') ?? undefined,
			parentName: filters.parentName, // Keep the name since it's not in URL
			configuration: searchParams.get('configuration') ?? undefined,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchParams]);

	const setKeywords = (keywords: string) => {
		setFilters((prev) => ({ ...prev, keywords }));
	};

	const setCategories = (categories?: string[]) => {
		setFilters((prev) => ({ ...prev, categories: categories?.filter(Boolean) }));
	};

	const setLabels = (labels?: string[]) => {
		setFilters((prev) => ({ ...prev, labels: labels?.filter(Boolean) }));
	};

	const setParent = (parent?: string, parentName?: string) => {
		setFilters((prev) => ({ ...prev, parent, parentName: parentName || '' }));
	};

	const setConfiguration = (configuration?: string) => {
		setFilters((prev) => ({ ...prev, configuration }));
	};

	const clearFilter = (filterType: 'keywords' | 'categories' | 'labels' | 'parent') => {
		const newFilters = { ...filters };

		switch (filterType) {
			case 'keywords':
				newFilters.keywords = '';
				break;
			case 'categories':
				newFilters.categories = undefined;
				break;
			case 'labels':
				newFilters.labels = undefined;
				break;
			case 'parent':
				newFilters.parent = undefined;
				newFilters.parentName = '';
				break;
		}

		setFilters(newFilters);
		updateSearchParams(newFilters);
	};

	const clearAllFilters = () => {
		const newFilters = {
			keywords: '',
			categories: undefined,
			labels: undefined,
			parent: undefined,
			parentName: '',
			configuration: filters.configuration, // Keep configuration if needed
		};

		setFilters(newFilters);
		navigate('/documents/search');
	};

	const updateSearchParams = (filterState: typeof filters) => {
		const newParams = new URLSearchParams();
		if (filterState.keywords) newParams.set('q', filterState.keywords);
		if (filterState.categories?.length)
			newParams.set('categories', filterState.categories.join(','));
		if (filterState.labels?.length) newParams.set('labels', filterState.labels.join(','));
		if (filterState.parent) newParams.set('parent', filterState.parent);
		if (filterState.configuration) newParams.set('configuration', filterState.configuration);

		// If all main filters are removed, navigate to base search
		if (
			!filterState.keywords &&
			!filterState.categories?.length &&
			!filterState.labels?.length &&
			!filterState.parent
		) {
			navigate('/documents/search');
		} else {
			setSearchParams(newParams);
		}
	};

	const applyFilters = () => {
		const params = new URLSearchParams();

		if (filters.keywords) {
			params.set('q', filters.keywords);
		}
		if (filters.categories?.length) {
			params.set('categories', filters.categories.join(','));
		}
		if (filters.labels?.length) {
			params.set('labels', filters.labels.join(','));
		}
		if (filters.parent) {
			params.set('parent', filters.parent);
		}
		if (filters.configuration) {
			params.set('configuration', filters.configuration);
		}

		navigate({
			pathname: '/documents/search',
			search: params.toString(),
		});
	};

	const contextValue = React.useMemo(
		() => ({
			filters,
			setKeywords,
			setCategories,
			setLabels,
			setParent,
			setConfiguration,
			clearFilter,
			clearAllFilters,
			applyFilters,
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[filters],
	);

	return <FilterContext.Provider value={contextValue}>{children}</FilterContext.Provider>;
};
export const useFilterContext = () => {
	const context = useContext(FilterContext);
	if (context === undefined) {
		throw new Error('useFilterContext must be used within a FilterProvider');
	}
	return context;
};
