import React from 'react';
// import ReactDOM from 'react-dom'; // For React 17
import * as Sentry from '@sentry/react';
import {
	QueryClient,
	QueryClientProvider
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createRoot } from 'react-dom/client'; // For React 18
import ReactGA from 'react-ga4';
import { BrowserRouter as Router } from 'react-router-dom';
import { FilterProvider } from '@this/impl/FilterContext';
import App from './App/App';
import { AuthContextProvider } from './contexts/authContext';
import { ThemeContextProvider } from './contexts/themeContext';
import './i18n';
import reportWebVitals from './reportWebVitals';
import './styles/styles.scss';

const queryClient = new QueryClient();

ReactGA.initialize('G-LJZF86YM08');

Sentry.init({
	dsn: 'https://05813db47a4eae0d35ccd6100763d084@o276599.ingest.us.sentry.io/4507654181027840',
	integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
	tracesSampleRate: 1.0,
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const children = (
	<AuthContextProvider>
		<ThemeContextProvider>
			<Router>
				<React.StrictMode>
					<FilterProvider>
						<QueryClientProvider client={queryClient}>
							<App />
							<ReactQueryDevtools initialIsOpen />
						</QueryClientProvider>
					</FilterProvider>
				</React.StrictMode>
			</Router>
		</ThemeContextProvider>
	</AuthContextProvider>
);

const container = document.getElementById('root');

// ReactDOM.render(children, container); // For React 17
createRoot(container as Element).render(children); // For React 18

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
